import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, Collapse } from "antd";
import { CSVLink } from "react-csv";
import Button from "@mui/material/Button";
import { DownloadIcon } from "../../../Resources/Icons/icons";
import Menu from "./Menu";
// import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import * as ExcelJS from "exceljs";
import { GetMasterReport } from "../../../Service/ReportService";
import { CircularProgress } from "@mui/material";
const { Panel } = Collapse;

const DownloadReport = (props) => {
  const { setOpenDrawer, header, apiReqBody, disable } = props;

  const [openLoader, setOpenLoader] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectSubMenu, setSelectSubMenu] = useState(header);
  const handleRegionChange = (regionId) => {
    console.log("handleRegionChange ", handleRegionChange);
    const updatedSelectedRegions = selectedRegions.includes(regionId)
      ? selectedRegions.filter((id) => id !== regionId)
      : [...selectedRegions, regionId];
    setSelectedRegions(updatedSelectedRegions);

    const region = Menu.find((item) => item._id === regionId);
    const submenuValues = region.Submenu || [];
    if (updatedSelectedRegions.includes(regionId)) {
      setSelectSubMenu((prevSelectSubMenu) => [
        ...prevSelectSubMenu,
        ...submenuValues.map((submenu) => ({
          header: submenu.header,
          key: submenu.key,
          id: submenu.id, // Changes by Arun
        })),
      ]);
    } else {
      setSelectSubMenu((prevSelectSubMenu) =>
        prevSelectSubMenu.filter(
          (submenu) => !submenuValues.some((item) => item.key === submenu.key)
        )
      );
    }
  };

  const handleRTOChange = (rtoKey) => {
    console.log("handleRTOChange ", handleRTOChange);
    const regionId = Menu.find((item) =>
      item.Submenu.some((rto) => rto.key === rtoKey)
    )?._id;

    if (regionId) {
      const allRTOs = Menu.find((item) => item._id === regionId)?.Submenu || [];
      const updatedSelectedRTOs = selectSubMenu.some(
        (rto) => rto.key === rtoKey
      )
        ? selectSubMenu.filter((item) => item.key !== rtoKey)
        : [...selectSubMenu, allRTOs.find((rto) => rto.key === rtoKey)];

      setSelectSubMenu(updatedSelectedRTOs);

      if (updatedSelectedRTOs.length === allRTOs.length) {
        setSelectedRegions((prevSelectedRegions) => [
          ...prevSelectedRegions,
          regionId,
        ]);
      } else {
        setSelectedRegions((prevSelectedRegions) =>
          prevSelectedRegions.filter((id) => id !== regionId)
        );
      }
    }
  };

  // Changes by Arun
  const downloadExcel = async () => {
    console.log("selectSubMenu ", selectSubMenu);
    const selectedHeader = [
      ...new Set(selectSubMenu.map((element) => element?.id)),
    ];
    console.log("selectedHeader ", selectedHeader);
    apiReqBody.isDownloadExcel = true;
    apiReqBody.selectedHeader = selectedHeader;
    // apiReqBody.selectSubMenu = selectSubMenu;
    setOpenLoader(true);
    await GetMasterReport("MASTER", apiReqBody)
      .then((res) => {
        // console.log("response : ", res);
        // // Create a Blob from the response data
        // const blob = new Blob([res], {
        //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        // });

        // // Create a link element and trigger download
        // const link = document.createElement("a");
        // link.href = window.URL.createObjectURL(blob);
        // link.download = "Master Report All Data.xlsx";
        // console.log("link ", link);
        // link.click();
        // // Clean up the URL object
        // URL.revokeObjectURL(link.href);
        const FormatedData = res?.data?.tableData ? res.data.tableData : [];
        FormatedData.map((element) => {
          let loginId = element.loginId;
          let lastStr = loginId.split("-").pop();
          element.loginID = lastStr
            ? loginId.replace("-" + lastStr, "")
            : loginId;
        });
        let wb = new ExcelJS.Workbook();
        let workbookName = "Master Report All Data.xlsx";
        let worksheetName = "Master Report";
        let ws = wb.addWorksheet(worksheetName, {
          properties: {
            tabColor: { argb: "FFFF0000" },
          },
        });

        ws.columns = selectSubMenu;
        // console.log("FormatedData  ", FormatedData);
        ws.addRows(FormatedData);

        ws.eachRow((row, rowNumber) => {
          row.eachCell({ includeEmpty: true }, (cell) => {
            // Set default border style for all cells (including empty cells)
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        });

        wb.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            workbookName
          );
        });
      })
      .finally(() => {
        setOpenLoader(false);
      });
    // let sectionSummary = {
    //   default: 0,
    //   customerDetail: 0,
    //   vechicleDetail: 0,
    //   premiumDetail: 0,
    //   policyPeriod: 0,
    //   userPayable: 0,
    //   branchPayable: 0,
    //   receivable: 0,
    // };
    // let sectionSubHeadingName = {
    //   default: "",
    //   customerDetail: "",
    //   vechicleDetail: "",
    //   premiumDetail: "",
    //   policyPeriod: "",
    //   userPayable: "",
    //   branchPayable: "",
    //   receivable: "",
    // };
    // const uniqueArray = selectSubMenu.filter(
    //   (obj, index, self) =>
    //     index ===
    //     self.findIndex(
    //       (t) => t.id === obj.id && t.header === obj.header && t.key === obj.key
    //     )
    // );

    // const occurenceArray = uniqueArray.reduce((acc, obj) => {
    //   const value = obj["id"];
    //   acc[value] = (acc[value] || 0) + 1;
    //   return acc;
    // }, {});
    // console.log("occurenceArray ", occurenceArray);
    // let count = 0;
    // uniqueArray.forEach((element) => {
    //   count++;
    //   if (element.id == "Policy Details") {
    //     sectionSummary.default = count;
    //     if (!sectionSubHeadingName.default) {
    //       sectionSubHeadingName.default = element.key;
    //     }
    //   }
    //   // if()
    //   if (element.id == "Customer Details") {
    //     sectionSummary.customerDetail = count;
    //     if (!sectionSubHeadingName.customerDetail) {
    //       sectionSubHeadingName.customerDetail = element.key;
    //     }
    //   }

    //   if (element.id == "Vehicle Details") {
    //     sectionSummary.vechicleDetail = count;
    //     if (!sectionSubHeadingName.vechicleDetail) {
    //       sectionSubHeadingName.vechicleDetail = element.key;
    //     }
    //   }
    //   if (element.id == "Premium Details") {
    //     sectionSummary.premiumDetail = count;
    //     if (!sectionSubHeadingName.premiumDetail) {
    //       sectionSubHeadingName.premiumDetail = element.key;
    //     }
    //   }
    //   if (element.id == "Policy Period") {
    //     sectionSummary.policyPeriod = count;
    //     if (!sectionSubHeadingName.policyPeriod) {
    //       sectionSubHeadingName.policyPeriod = element.key;
    //     }
    //   }
    //   if (element.id == "User Payable") {
    //     sectionSummary.userPayable = count;
    //     if (!sectionSubHeadingName.userPayable) {
    //       sectionSubHeadingName.userPayable = element.key;
    //     }
    //   }
    //   if (element.id == "Branch Payable") {
    //     sectionSummary.branchPayable = count;
    //     if (!sectionSubHeadingName.branchPayable) {
    //       sectionSubHeadingName.branchPayable = element.key;
    //     }
    //   }
    //   if (element.id == "Receivable") {
    //     sectionSummary.receivable = count;
    //     if (!sectionSubHeadingName.receivable) {
    //       sectionSubHeadingName.receivable = element.key;
    //     }
    //   }
    // });

    // console.log("uniqueArray ", uniqueArray);
    // console.log("sectionSummary ", sectionSummary);
    // console.log("sectionSubHeadingName ", sectionSubHeadingName);

    // let wb = new ExcelJS.Workbook();
    // let workbookName = "Master Report All Data.xlsx";
    // let worksheetName = "Master Report";
    // let ws = wb.addWorksheet(worksheetName, {
    //   properties: {
    //     tabColor: { argb: "FFFF0000" },
    //   },
    // });

    // ws.columns = selectSubMenu;
    // ws.columns = excelData;

    // const merge = [];
    // const headerKeyArray = uniqueArray.map((e) => e.key || "");
    // let subHeaderObj = JSON.parse(JSON.stringify(excelData[0], headerKeyArray));
    // Object.entries(subHeaderObj).forEach(([key, value]) => {
    //   subHeaderObj[key] = "";
    // });
    // Object.entries(sectionSummary).forEach(([key, value]) => {
    //   console.log(`${key}: ${value}`);
    //   if (key == "default" && value) {
    //     subHeaderObj[sectionSubHeadingName.default] = "Default";
    //     merge.push({ s: { r: 2, c: 0 }, e: { r: 2, c: value } });
    //   }
    //   if (key == "customerDetail" && value) {
    //     subHeaderObj[sectionSubHeadingName.customerDetail] = "Customer Details";
    //     merge.push({
    //       s: { r: 2, c: sectionSummary.default + 1 },
    //       e: { r: 2, c: value },
    //     });
    //   }
    //   if (key == "vechicleDetail" && value) {
    //     subHeaderObj[sectionSubHeadingName.vechicleDetail] = "Vehicle Details";
    //     merge.push({
    //       s: { r: 2, c: sectionSummary.customerDetail + 1 },
    //       e: { r: 2, c: value },
    //     });
    //   }
    //   if (key == "premiumDetail" && value) {
    //     subHeaderObj[sectionSubHeadingName.premiumDetail] = "Premium Details";
    //     merge.push({
    //       s: { r: 2, c: sectionSummary.vechicleDetail + 1 },
    //       e: { r: 2, c: value },
    //     });
    //   }
    //   if (key == "policyPeriod" && value) {
    //     subHeaderObj[sectionSubHeadingName.policyPeriod] = "Policy Period";
    //     merge.push({
    //       s: { r: 2, c: sectionSummary.premiumDetail + 1 },
    //       e: { r: 2, c: value },
    //     });
    //   }
    //   if (key == "userPayable" && value) {
    //     subHeaderObj[sectionSubHeadingName.userPayable] = "User Payable";
    //     merge.push({
    //       s: { r: 2, c: sectionSummary.policyPeriod + 1 },
    //       e: { r: 2, c: value },
    //     });
    //   }
    //   if (key == "branchPayable" && value) {
    //     subHeaderObj[sectionSubHeadingName.branchPayable] = "Branch Payable";
    //     merge.push({
    //       s: { r: 2, c: sectionSummary.userPayable + 1 },
    //       e: { r: 2, c: value },
    //     });
    //   }
    //   if (key == "receivable" && value) {
    //     subHeaderObj[sectionSubHeadingName.receivable] = "Receivable";
    //     merge.push({
    //       s: { r: 2, c: sectionSummary.branchPayable + 1 },
    //       e: { r: 2, c: value },
    //     });
    //   }
    // });

    // console.log("excelData ", excelData);
    // ws.addRow(subHeaderObj);
    // ws.addRows(excelData);
    // merge.forEach((element) => {
    //   ws.mergeCells(element.s.r, element.s.c, element.e.r, element.e.c);
    // });

    // ws.getRow(1).font = { bold: true };
    // ws.getRow(2).font = { bold: true, size: 15 };
    // ws.getRow(2).alignment = { horizontal: "center" };

    // Set borders for specific cells or ranges

    // const headerLabelArray = uniqueArray.map((e) => e.label || "");
    // const headerKeyArray = uniqueArray.map((e) => e.key || "");

    // let objectKeys = Object.keys(excelData[0]);
    // const removedHeaders = objectKeys.filter(
    //   (element) => !headerKeyArray.includes(element)
    // );
    // console.log("objectKeys ", objectKeys);
    // console.log("removedHeaders ", removedHeaders);
    // let newArray = await Promise.all(
    //   excelData.map((obj) => {
    //     removedHeaders.forEach((key) => delete obj[key]);
    //     const formattedObj = JSON.parse(JSON.stringify(obj, headerKeyArray));
    //     return formattedObj;
    //   })
    // );
    // // console.log("headerLabelArray ", headerLabelArray);
    // console.log("newArray ", newArray);
    // // newArray.unshift({ approveDate: "Basic" });
    // // let filterTableData = excelData.map(Element)
    // // Create a new workbook
    // const wb = XLSX.utils.book_new();
    // const ws = XLSX.utils.json_to_sheet([]);
    // XLSX.utils.sheet_add_aoa(ws, [headerLabelArray]);

    // // Customize
    // const merge = [
    //   // { s: { r: 0, c: 0 }, e: { r: 0, c: 0 } },
    //   // { s: { r: 2, c: 0 }, e: { r: 2, c: 10 } },
    // ];

    // console.log("subHeaderObj ", subHeaderObj);
    // console.log("merge ", merge);
    // newArray.unshift(subHeaderObj);
    // // console.log("excelData ", excelData);
    // //Starting in the second row to avoid overriding and skipping headers
    // XLSX.utils.sheet_add_json(ws, newArray, {
    //   origin: "A2",
    //   skipHeader: true,
    // });

    // ws["!merges"] = merge;
    // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // // Get the range of the worksheet
    // const range = XLSX.utils.decode_range(ws["!ref"]);

    // // Define border style
    // const borderStyle = {
    //   top: { style: "thin" },
    //   bottom: { style: "thin" },
    //   left: { style: "thin" },
    //   right: { style: "thin" },
    // };

    // // Loop through each column and set border for each cell in the column
    // for (let col = range.s.c; col <= range.e.c; col++) {
    //   for (let row = range.s.r; row <= range.e.r; row++) {
    //     const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
    //     const cell = ws[cellAddress];

    //     if (!cell) continue; // Skip empty cells

    //     // Apply border style to each individual side of the cell's border
    //     ["top", "bottom", "left", "right"].forEach((side) => {
    //       const border = borderStyle[side];
    //       if (border) {
    //         if (!cell.s) cell.s = {};
    //         if (!cell.s.border) cell.s.border = {};
    //         cell.s.border[side] = border;
    //       }
    //     });
    //   }
    // }

    // XLSX.writeFile(wb, "Master Report All Data.xlsx");
  };

  // const csvFile = {
  //   filename: "Master Report All Data",
  //   headers: selectSubMenu,
  //   // data: excelData?.filter(Boolean),
  //   data: excelData, // Changes by Arun
  // };

  return (
    <div className="MainRenderinContainer">
      <Grid container className="DrawerHeader">
        <Grid item xs={6} sm={6}>
          <Typography>Filter Report</Typography>
        </Grid>
        <Grid item xs={6} sm={6} className="d-flex justify-content-end">
          <CloseIcon
            onClick={() => setOpenDrawer(false)}
            sx={{ cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      <div className="container-fluid">
        <Grid container sx={{ width: "100%" }}>
          {Menu?.map((main, i) => (
            <Grid item xs={12} sm={4} key={i}>
              <Collapse
                style={{ borderRadius: 0, border: "none" }}
                expandIconPosition={"end"}
                collapsible={"icon"}
              >
                <Panel
                  style={{ borderRadius: "0px" }}
                  header={
                    <div style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={selectedRegions.includes(main._id)}
                        onChange={() => handleRegionChange(main._id)}
                      />
                      &nbsp;&nbsp;{main._id}
                    </div>
                  }
                  key={i}
                >
                  <Grid
                    container
                    sx={{ width: "100%", padding: "10px 10px 10px 70px" }}
                    spacing={2}
                  >
                    {main.Submenu.map((subMain, index) => (
                      <Grid item xs={12} sm={12} key={index}>
                        <Checkbox
                          checked={selectSubMenu.some(
                            (item) => item.key === subMain.key
                          )}
                          onChange={() => handleRTOChange(subMain.key)}
                        />
                        &nbsp;&nbsp;{subMain.header}
                      </Grid>
                    ))}
                  </Grid>
                </Panel>
              </Collapse>
            </Grid>
          ))}
          <Grid item xs={12} sm={12} className="Master_Header_Container">
            <Button
              className="Master_Header_create_Button w-100"
              endIcon={!openLoader ? <DownloadIcon /> : null}
              sx={{ width: { xs: "100%", sm: "fit-content" } }}
              onClick={downloadExcel}
              // disabled={openLoader} // disable the button when loading
              startIcon={
                openLoader ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              } // add spinner as icon
            >
              {/* <CSVLink className="Download_Excel_Button" {...csvFile}> */}
              {openLoader ? "Loading..." : "Download Excel"}
              {/* </CSVLink> */}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DownloadReport;
