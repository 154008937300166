import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import FloatLabel from "../../../UiComponents/FloatLabel/FloatLabel";
import TextField from "@mui/material/TextField";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionSharpIcon from "@mui/icons-material/DescriptionSharp";
import {
  GetFilterCCEntry,
  GetFilterCCEntryAll,
  GetPolicyFileById,
  UpdatePolicyList,
  ticketAlreadyExist,
  unlinkTicketNumber,
  CheckTheTickNum,
  getPolicyBazaarTicketNumber,
} from "../../../Service/SearchPolicy";
import { ToastError, ToastSuccess } from "../../../UiComponents/Toaster/Toast";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { GetSubBookingCode } from "../../../Service/SubBookingCodeService";
import { GetBookingCode } from "../../../Service/BookingCodeService";
import { GetVehicleMake } from "../../../Service/VehicleMakeService";
import DownloadIcon from "@mui/icons-material/Download";
import Loader from "../../../UiComponents/Loader/Loader";

const ViewCCentry = (props) => {
  const {
    selectedData,
    queryParam,
    setOpenViewCC,
    setPolicyList,
    setCount,
    setTotalPremium,
    edit,
    filterBy,
    loader,
  } = props;
  useEffect(() => loader(false), []);
  const [ticketNumber, setTicketNumber] = useState(selectedData.ticketNumber);
  const [filterData, setFilterData] = useState({
    bookingCodeId: "",
    subBookingCodeId: "",
  });

  const [bookingCodeDetails, setBookingCodeDetails] = useState([]);
  const [subBookingCode, setSubBookingCode] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);

  const [displayErr, setDisplayErr] = useState("none");
  const [displayMsg, setDisplayMsg] = useState(
    " This ticket number is already exist"
  );

  const GetData = async () => {
    try {
      const result = await GetFilterCCEntry({
        ...filterBy,
        ...queryParam,
      });
      if (result) {
        setPolicyList(result.data.tableData);
        setCount(result?.data?.totalRecords);
        setTotalPremium(result?.data?.totalPremium);
        setOpenViewCC(false);
      }
    } catch (error) {
      console.log("Error from tickNumber", error);
    }
  };
  const UserId = sessionStorage.getItem("UserId");
  const id = selectedData._id;
  const updateTickNumber = async (check) => {
    if (displayErr === "none" && ticketNumber) {
      setDisplayErr("none");
      try {
        const result = await UpdatePolicyList(id, {
          ticketNumber: ticketNumber,
          ticketCreatedBy: UserId,
        });
        if (result) {
          const res = await GetFilterCCEntry({
            ...filterBy,
            ...queryParam,
          });
          setPolicyList(res.data.tableData);
          setCount(res?.data?.totalRecords);
          setTotalPremium(res?.data?.totalPremium);
          setOpenViewCC(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      if (!ticketNumber) {
        setDisplayMsg("enter the ticket number");
        setDisplayErr("block");
      }
    }
  };

  const getTicketNumber = async () => {
    setOpenLoader(true);
    const res = await getPolicyBazaarTicketNumber(selectedData._id);
    if (res?.data?.message) {
      setTicketNumber(res.data.message);
    }
    setOpenLoader(false);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setFilterData((pre) => ({
      ...pre,
      bookingCodeId: selectedValue,
    }));
  };
  const GetBookingCodeDetails = () => {
    GetBookingCode({ isAscending: true })
      .then((res) => {
        const modifiedBookingCodeDetails = res?.data
          .map((e) => {
            if (e.isEnabled) {
              return {
                ...e,
                label: e?.bookingCode,
                value: e?._id,
              };
            } else {
              return null;
            }
          })
          .filter(Boolean);
        setBookingCodeDetails(modifiedBookingCodeDetails);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetSubBookingCodeDetails = () => {
    GetSubBookingCode({ isAscending: true })
      .then((res) => {
        const SubBookingCodeDetails = res.data.map((e) => {
          if (e.isEnabled) {
            return {
              ...e,
              label: e.subBookingCode,
              value: e._id,
            };
          } else {
            return null;
          }
        });
        const selectedBookingCode = bookingCodeDetails.find(
          (e) => e._id === filterData.bookingCodeId
        );
        const filteredSubBookingCodes = SubBookingCodeDetails.filter(
          (item) => item.bookingCode === selectedBookingCode?.bookingCode
        );
        // console.log(filteredSubBookingCodes);
        setSubBookingCode(filteredSubBookingCodes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AlreadyExistTicketNum = async (data) => {
    if (data) {
      const res = await CheckTheTickNum(data);
      if (res?.data?.data?.length) {
        // setTicketNumber("");
        setDisplayErr("block");
      } else {
        setDisplayErr("none");
      }
    }
  };

  const AlreadyExist = async () => {
    const result = await ticketAlreadyExist(id, {
      createdBy: UserId,
    });
    try {
      if (result) {
        const res = await GetFilterCCEntry({
          status: "alreadyExist",
          ...queryParam,
        });
        setPolicyList(res.data.tableData);
        setCount(res?.data?.totalRecords);
        setTotalPremium(res?.data?.totalPremium);
        setOpenViewCC(false);
        ToastError("Already Exist");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateBookingCodes = async () => {
    try {
      const result = await unlinkTicketNumber(id, filterData);
      if (result) {
        GetData();
        // ToastSuccess("Booking Code Updated");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const ViewPdfFunction = () => {
  //   setOpenLoader(true);
  //   GetPolicyFileById(selectedData?._id)
  //     .then((res) => {
  //       const pdfUrl = res.data.policyFile.downloadURL;
  //       return pdfUrl;
  //     })
  //     .then((pdfUrl) => {
  //       setOpenLoader(false);
  //       const pdfWindow = window.open("", "_blank");
  //       pdfWindow?.document?.write(
  //         `<embed src="data:application/pdf;base64,${pdfUrl}" width="100%" height="100%" />`
  //       );
  //     });
  // };

  const ViewPdfFunction = () => {
    setOpenLoader(true);
    GetPolicyFileById(selectedData?._id)
      .then((res) => {
        console.log(res.data);
        const pdfUrl = res?.data?.policyFile?.downloadURL;
        return pdfUrl;
      })
      .then((pdfUrl) => {
        console.log("url", pdfUrl);
        const blobData = base64toBlob(pdfUrl, "application/pdf");
        const blobUrl = URL.createObjectURL(blobData);
        setOpenLoader(false);
        const pdfWindow = window.open("", "_blank");
        pdfWindow?.document?.write(
          // `<embed src="data:application/pdf;base64,${pdfUrl}" width="100%" height="100%" />`
          `<embed src="${blobUrl}" width="100%" height="100%" />`
        );
      });
  };

  const base64toBlob = (base64Data, contentType) => {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  const [vehicleValue, setVehicleValue] = useState();
  const GetVehicleMakeDetails = () => {
    GetVehicleMake().then((res) => {
      const d = res.data.find((n) => n._id === selectedData?.make);
      setVehicleValue(d?.vehicleMake);
    });
  };

  React.useEffect(() => GetVehicleMakeDetails(), [selectedData]);

  React.useEffect(() => {
    GetBookingCodeDetails();
    GetSubBookingCodeDetails();
  }, [filterData.bookingCodeId]);

  const DownloadFileFunction = () => {
    setOpenLoader(true);
    GetPolicyFileById(selectedData?._id)
      .then((res) => {
        const pdfUrl = res?.data?.policyFile?.downloadURL;
        return pdfUrl;
      })
      .then((pdfUrl) => {
        setOpenLoader(false);
        const base64PDFUrl = pdfUrl;
        const byteCharacters = atob(base64PDFUrl);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${
          selectedData?.registrationNumber.toLowerCase() !== "new"
            ? selectedData?.registrationNumber
            : selectedData?.customerName
        }.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  // console.log(selectedData)

  return (
    <>
      {edit === "ccEntry" ? (
        <div className="MainRenderinContainer">
          <Grid container className="DrawerHeader">
            <Grid item xs={6} sm={6}>
              <Typography>CC Entry</Typography>
            </Grid>
            <Grid item xs={6} sm={6} className="d-flex justify-content-end">
              <CloseIcon
                onClick={() => setOpenViewCC(false)}
                sx={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <div className="container-fluid" style={{ padding: "20px" }}>
            <Typography className="EditPageHeadingTittle">
              Policy Files
            </Typography>
            <Grid container rowGap={2} p={2} className="CCEntryForm">
              <Grid item xs={12} sm={4} className="d-flex" sx={{ gap: 2 }}>
                <Button
                  className="w-50 TabelButton"
                  variant="contained"
                  endIcon={<PictureAsPdfIcon />}
                  onClick={() => ViewPdfFunction()}
                >
                  View PDF
                </Button>
                {/* <Button
                  className="w-50 TabelButton"
                  variant="contained"
                  endIcon={<DescriptionSharpIcon />}
                >
                  Other Documents
                </Button> */}
                <Button
                  className="w-50 TabelButton"
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  onClick={DownloadFileFunction}
                >
                  Download PDF
                </Button>
              </Grid>
              <Grid item xs={12} sm={8} />

              {/* <Grid item xs={12} sm={4} className="d-flex" mt={3}>
                <Typography className="CCHeading">User Name</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.userId?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex" mt={3}>
                <Typography className="CCHeading">Branch</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.userId?.name}
                </Typography>
              </Grid> */}
            </Grid>
            {/* <hr /> */}
            <Typography className="EditPageHeadingTittle">
              Policy Details
            </Typography>
            <Grid container rowGap={2} p={2} className="CCEntryForm">
              <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">Issue Date</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {moment(selectedData?.issueDate).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">Company Name</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.companyId?.companyName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">Product</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.productId?.product} -{" "}
                  {selectedData?.subProductId?.subProduct}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">Sub Product</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.subProductId?.subProduct}
                </Typography>
              </Grid> */}
              <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">Policy Type</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.policyTypeId?.policyType}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">Booking Code</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.bookingCodeId?.bookingCode}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">Sub Booking Code</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.subBookingCodeId?.subBookingCode}
                </Typography>
              </Grid> */}

              <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">Policy Number</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.policyNumber}
                </Typography>
              </Grid>
            </Grid>
            <hr />
            <Typography className="EditPageHeadingTittle">
              Customer Details
            </Typography>
            <Grid container rowGap={2} p={2} className="CCEntryForm">
              <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">Customer Name</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.customerName}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">
                  Registration Number
                </Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.registrationNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">Total Premium</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.totalPremium}
                </Typography>
              </Grid>
            </Grid>
            <hr />
            <Typography className="EditPageHeadingTittle">
              Payment Details
            </Typography>
            <Grid container rowGap={2} p={2} className="CCEntryForm">
              <Grid item xs={12} sm={4} className="d-flex">
                <Typography className="CCHeading">Payment Mode</Typography>
                <Typography className="CCdivider">:</Typography>
                <Typography className="CCContent">
                  {selectedData?.paymentMode}
                </Typography>
              </Grid>
            </Grid>
            <Typography className="EditPageHeadingTittle">
              Company Entry Number
            </Typography>
            <Grid container gap={2} p={2}>
              <Grid item xs={12} sm={4}>
                <FloatLabel label="Ticket Number" value={ticketNumber}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="InputFiled w-100"
                    onChange={(e) => setTicketNumber(e.target.value)}
                    value={ticketNumber}
                    onBlur={(e) => {
                      if (ticketNumber) {
                        setDisplayMsg(" This ticket number is already exist");
                        AlreadyExistTicketNum(e.target.value);
                      } else {
                        setDisplayMsg("");
                      }
                    }}
                  />
                </FloatLabel>
                <span
                  style={{
                    fontSize: "12px",
                    color: "red",
                    display: displayErr,
                  }}
                >
                  {displayMsg}
                </span>
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex" gap={2}>
                <Button
                  onClick={() => updateTickNumber("")}
                  className="TabelButton"
                >
                  Update Ticket Number
                </Button>
                <Button onClick={AlreadyExist} className="TabelButton">
                  Already Exists
                </Button>
                <Button
                  onClick={() => getTicketNumber()}
                  className="TabelButton"
                >
                  Get Ticket Number
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : edit === "edit" ? (
        <>
          <DialogTitle>Edit Ticket Number</DialogTitle>
          <DialogContent>
            <TextField
              id="outlined-basic"
              variant="outlined"
              className="InputFiled w-100"
              onChange={(e) => setTicketNumber(e.target.value)}
              value={ticketNumber}
              onBlur={(e) => {
                if (ticketNumber) {
                  setDisplayMsg(" This ticket number is already exist");
                  AlreadyExistTicketNum(e.target.value);
                } else {
                  setDisplayMsg("");
                }
              }}
            />
            <span
              style={{
                fontSize: "12px",
                color: "red",
                display: displayErr,
              }}
            >
              {displayMsg}
            </span>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenViewCC(false)}>Cancel</Button>
            <Button onClick={() => updateTickNumber("update")}>Update</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <Typography
            variant="h6"
            sx={{
              p: 3,
              pb: 1,
            }}
          >
            CC Unlink Ticket
          </Typography>
          <DialogContent
            sx={{
              display: "flex",
              gap: 2,
              // justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <FloatLabel
                label="Booking Code"
                value={filterData?.bookingCodeId}
              >
                <Select
                  className="w-100 DropdownField InputFiled"
                  name="BookingCode"
                  onChange={handleSelectChange}
                  value={filterData?.bookingCodeId}
                >
                  {bookingCodeDetails?.map((e) => {
                    return (
                      <MenuItem value={e._id} key={e._id}>
                        {e.bookingCode}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FloatLabel>
            </Box>
            <Box sx={{ width: "100%" }}>
              <FloatLabel
                label="Sub Booking Code"
                value={filterData?.subBookingCodeId}
              >
                <Select
                  className="w-100 DropdownField InputFiled"
                  name="SubBookingCode"
                  onChange={(e) => {
                    setFilterData((pre) => ({
                      ...pre,
                      subBookingCodeId: e.target.value,
                    }));
                  }}
                  value={filterData?.subBookingCodeId}
                >
                  {subBookingCode?.map((e) => {
                    return (
                      <MenuItem value={e._id} key={e._id}>
                        {e.subBookingCode}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FloatLabel>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenViewCC(false)}>Cancel</Button>
            <Button onClick={updateBookingCodes}>Unlink</Button>
          </DialogActions>
        </>
      )}
      <Loader open={openLoader} />
    </>
  );
};

export default ViewCCentry;
